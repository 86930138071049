import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useQuery, gql } from "@apollo/client";
import { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Primarymv(props: any) {
  const [apiData, setApiData] = useState({
    vision: "Loading...",
    mission: "Loading...",
    objectives: "",
  });


  const GET_HOME = gql`
    query {
      home {
        data {
          attributes {
            vision
            mission
            objectives
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_HOME, {
    onCompleted(data) {

      setApiData({
        vision: data.home.data.attributes.vision,
        mission: data.home.data.attributes.mission,
        objectives: data.home.data.attributes.objectives
      });
    }

  });
  const matches = useMediaQuery("(min-width:840px)");
  return (
    <Card className="pdbga" style={{ width: matches ? "85%" : "95%", margin: "auto" }}>
      <CardContent>
        <Typography
          variant="h4"
          style={{
            fontFamily: "Karla,sans-serif",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: "20px",
          }}
        >
          VISION
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontFamily: "Montserrat,sans-serif",
            textAlign: "center",
            // fontStyle: "italic",
            marginTop: "30px",
            paddingBottom: "20px",
          }}
        >
          {apiData.vision}
          <br />
          <br />
        </Typography>
        <Divider variant="middle" />
        <Typography
          variant="h4"
          style={{
            fontFamily: "Karla,sans-serif",
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "30px",
            paddingBottom: "20px",
          }}
        >
          MISSION
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontFamily: "Montserrat,sans-serif",
            marginTop: "15px",
            textAlign: "center",
            // fontStyle: "italic",
            paddingBottom: "40px",
          }}
        >
          {apiData.mission}
        </Typography>
        <br />
        <Divider variant="middle" />
        <Typography
          variant="h4"
          style={{
            fontFamily: "Karla,sans-serif",
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "30px",
            paddingBottom: "20px",
          }}
        >
          OBJECTIVES
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontFamily: "Montserrat,sans-serif",
            marginTop: "15px",
            // textAlign: "center",
            // fontStyle: "italic",
            paddingBottom: "40px",
            textAlign: "justify",
            textJustify: "inter-word"
          }}
        >
          <div
            className={"ql-container ql-snow"}
            style={{ width: matches ? "85%" : "95%", margin: "0 auto", fontFamily: "Montserrat,sans-serif", }}
          >
            <div
              style={{
                fontSize: "1rem", textAlign: "justify",
                textJustify: "inter-word",lineHeight:2
              }}
              className={"ql-editor"}
              dangerouslySetInnerHTML={{ __html: apiData.objectives }}
            ></div>
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
}

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import { BsFacebook } from 'react-icons/bs';
import { FaInstagram ,FaYoutube} from 'react-icons/fa';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: "100px",
    },
    groupa: {
      paddingLeft: "34px",
      width: "100%",
    },
    headinga: {
      marginBottom: "8px",
      fontFamily: "Montserrat,sans-serif",
    },
    navButton: {
      fontFamily: "Karla,sans-serif",
      fontWeight: "bolder",
      fontSize: "13px",
    },
    iconsright:{
      marginRight:"20px",
    },
    sociallinks:{
      position:"absolute",
      right:"50px",
      top:"30px"
    }
  })
);

export default function PrimaryNavBar() {
  const classes = useStyles();
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const [anchorEl4, setAnchorEl4] = React.useState<null | HTMLElement>(null);

  const handleClick =
    (obj: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
      obj(event.currentTarget);
    };

  const handleClose = (obj: any) => () => {
    obj(null);
  };

  return (
    <div>
      <AppBar
        position="static"
        style={{ paddingBottom: "5px", paddingTop: "5px" }}
      >
        <Toolbar>
          <div>
            <a href="/">
              <img
                className={classes.logo}
                src="/static/logo.png"
                alt="COD logo"
              />
            </a>
          </div>
          <div className={classes.groupa}>
            <a href="/">
              <h2 className={classes.headinga}>
                COD THAMARASSERY
              </h2>
            </a>
            <span>24/7 Support: 0495-2223022{", "}Email: codtmsy@gmail.com</span>
            <div className={classes.sociallinks}>
            <a href="https://www.facebook.com/CODThamrassery/" target="blank" className={classes.iconsright}><BsFacebook color="black" fontSize={"1.5rem"} /></a>
            <a href="https://instagram.com/cod_thamarassery?igshid=YmMyMTA2M2Y=" className={classes.iconsright} target="blank" ><FaInstagram color="black" fontSize={"1.5rem"} /></a>
            <a href="" className={classes.iconsright} ><FaYoutube color="black" fontSize={"1.5rem"} /></a>
            </div>
            <div style={{ width: "100%" }}>
              <Box display="flex" justifyContent="flex-end">
                <div>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="secondary"
                    className={classes.navButton}
                    href="/"
                  >
                    HOME
                  </Button>
                </div>

                <div>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick(setAnchorEl1)}
                    color="secondary"
                    className={classes.navButton}
                  >
                    ABOUT US
                  </Button>
                  <Menu
                    id="programmes"
                    anchorEl={anchorEl1}
                    keepMounted
                    open={Boolean(anchorEl1)}
                    onClose={handleClose(setAnchorEl1)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    elevation={0}
                    getContentAnchorEl={null}
                  >
                    <a href="/aboutus/organizational_profile" style={{ color: "#03205c" }}>
                      <MenuItem onClick={handleClose(setAnchorEl1)}>
                        Organizational Profile
                      </MenuItem>
                    </a>
                    <Divider />
                    <a href="/aboutus/history" style={{ color: "#03205c" }}>
                      <MenuItem onClick={handleClose(setAnchorEl1)}>
                        History
                      </MenuItem>
                    </a>
                    <Divider />
                    <a href="/aboutus/governing_body" style={{ color: "#03205c" }}>
                      <MenuItem onClick={handleClose(setAnchorEl1)}>
                        Governing Body
                      </MenuItem>
                    </a>
                    <Divider />
                    <a href="/aboutus/miles_stones_and_achievements" style={{ color: "#03205c" }}>
                      <MenuItem onClick={handleClose(setAnchorEl1)}>
                        Mile Stones and Achievements
                      </MenuItem>
                    </a>
                    <Divider />
                    <a href="/aboutus/credentials" style={{ color: "#03205c" }}>
                      <MenuItem onClick={handleClose(setAnchorEl1)}>
                        Credentials
                      </MenuItem>
                    </a>
                    <Divider />
                    <a href="/aboutus/impact" style={{ color: "#03205c" }}>
                      <MenuItem onClick={handleClose(setAnchorEl1)}>
                        Impact
                      </MenuItem>
                    </a>
                  </Menu>
                </div>

                <div>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="secondary"
                    className={classes.navButton}
                    href="/projects"
                  >
                    PROJECTS
                  </Button>
                </div>
                <div>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="secondary"
                    className={classes.navButton}
                    href="/activities"
                  >
                    ACTIVITIES
                  </Button>
                </div>
                <div>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick(setAnchorEl3)}
                    color="secondary"
                    className={classes.navButton}
                  >
                    GET INVOLVED
                  </Button>
                  <Menu
                    id="research"
                    anchorEl={anchorEl3}
                    keepMounted
                    open={Boolean(anchorEl3)}
                    onClose={handleClose(setAnchorEl3)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    elevation={0}
                    getContentAnchorEl={null}
                  >
                    <a href="/getInvolved/donate" style={{ color: "#03205c" }}>
                      <MenuItem>Donate Now</MenuItem>
                    </a>
                    <Divider />
                    <a href="/getInvolved/jobs" style={{ color: "#03205c" }}>
                      <MenuItem>Jobs</MenuItem>
                    </a>
                    <Divider />
                    <a href="/getInvolved/volunteers" style={{ color: "#03205c" }}>
                      <MenuItem>Volunteers</MenuItem>
                    </a>
                    <Divider />
                    <a href="/getInvolved/internship" style={{ color: "#03205c" }}>
                      <MenuItem>Internship</MenuItem>
                    </a>
                    <Divider />
                    <a href="/getInvolved/individual_support" style={{ color: "#03205c" }}>
                      <MenuItem>Individual Support</MenuItem>
                    </a>
                    <Divider />
                    <a href="/getInvolved/coporate_partnership" style={{ color: "#03205c" }}>
                      <MenuItem>Corporate Partnership</MenuItem>
                    </a>
                  </Menu>
                </div>

                <div>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick(setAnchorEl4)}
                    color="secondary"
                    className={classes.navButton}
                  >
                    MEDIA
                  </Button>
                  <Menu
                    id="facilities"
                    anchorEl={anchorEl4}
                    keepMounted
                    open={Boolean(anchorEl4)}
                    onClose={handleClose(setAnchorEl4)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    elevation={0}
                    getContentAnchorEl={null}
                  >
                    <a href="/gallery" style={{ color: "#03205c" }}>
                      <MenuItem>Gallery</MenuItem>
                    </a>
                    <Divider />
                    <a href="/news" style={{ color: "#03205c" }}>
                      <MenuItem>News</MenuItem>
                    </a>
                    <Divider />
                    <a href="/annual_report" style={{ color: "#03205c" }}>
                      <MenuItem>Annual Reports</MenuItem>
                    </a>
                    <Divider />
                    <a href="/story_of_change" style={{ color: "#03205c" }}>
                      <MenuItem>Stories Of Change</MenuItem>
                    </a>
                    <Divider />
                    <a href="/blogs" style={{ color: "#03205c" }}>
                      <MenuItem>Blogs</MenuItem>
                    </a>
                  </Menu>
                </div>

                <div>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="secondary"
                    className={classes.navButton}
                    href="/contact_us"
                  >
                    CONTACT
                  </Button>
                </div>

              </Box>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

import CircularProgress from "@material-ui/core/CircularProgress";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { apiurl } from "../../varconfig";
import { useQuery, gql } from "@apollo/client";
import { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface AppProps {
  url_slug: string;
  child_url: string;
}

export default function PrimaryCarousel(props: AppProps) {
  const [apiData, setApiData] = useState({ details: [] });

  // const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const GET_HOMEIMGS = gql`
    query {
      home {
        data {
          attributes {
            images {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  `;

  const {
    loading: isLoadState,
    error,
    data,
  } = useQuery(GET_HOMEIMGS, {
    onCompleted(data) {
      let data_array: any = [];

      data?.home.data?.attributes.images?.data.map((each: any) => {
        data_array.push({
          img_url: apiurl + each.attributes.url,
        });
      });
      setApiData({ details: data_array });
    },
  });

  const matches = useMediaQuery("(min-width:840px)");

  function imgSliders(matches: any) {
    let da = apiData.details.map(function (objs, index) {
      return (
        <div
          key={index}
          style={{
            width: "100%",
            height: matches ? "70vh" : "50vh",
          }}
        >
          <img
            style={{
              objectFit: "cover",
              objectPosition: matches? "0% 40%":"35% 40%"
            }}
            height={"100%"}
            width={"100%"}
            src={objs["img_url"]}
            alt={"Error with img"}
          />
        </div>
      );
    });
    return da;
  }
  function loadingsliders() {
    let d = [0, 0].map(function (obj, index) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <CircularProgress />
          <h4>Getting images...</h4>
        </div>
      );
    });
    return d;
  }

  // function updateWindowDimensions() {
  //   setDimensions({
  //     width: document.body.clientWidth,
  //     height: document.body.clientHeight,
  //   });
  // }

  // useEffect(() => {
  //   updateWindowDimensions();
  //   window.addEventListener("resize", updateWindowDimensions);

  //   // returned function will be called on component unmount
  //   return () => {
  //     window.removeEventListener("resize", updateWindowDimensions);
  //   };
  // }, []);

  return (
    <div >
      <Carousel
        showArrows={true}
        emulateTouch={true}
        interval={3000}
        autoPlay={true}
        infiniteLoop={true}
        showIndicators={false}
        showThumbs={false}
        stopOnHover={false}
        swipeable={false}
      >
        {!isLoadState ? imgSliders(matches) : loadingsliders()}
      </Carousel>
    </div>
  );
}

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { apiurl } from "../../varconfig";
import { useQuery, gql } from "@apollo/client";
import { useState } from "react";
import { formatDate } from "../utils/format-date";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactMarkdown from "react-markdown";

interface AppProps {
  url_slug: string;
  category: string
}

export default function Newspagecomp(props: AppProps) {
  const [isLoadState, setIsLoadState] = useState(true);
  const [apiData, setapiData] = useState({
    data: [
      {
        id: 0,
        title: "Loading...",
        date: "loading...",
        url: "Loading..",
        description: "<p>Loading... Please wait...</p>",
        attachment: "Loading..",
      },
    ],
    lastPage: true,
    currentPage: 1,
  });

  const matches = useMediaQuery("(min-width:840px)");
  const matches1 = useMediaQuery("(max-width:380px)");

  const GET_PAGE = gql`
  query {
    ${props.url_slug}( pagination:{page: ${apiData.currentPage}, pageSize: 4}) {
      meta{
        pagination{
          pageCount
        }
      }
      data {
        id
        attributes {
          title
          date
          url
          description
          attachment {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  } 
  `;

  const { loading, error, data, refetch } = useQuery(GET_PAGE, {
    onCompleted(data) {
      let data_array: any = [];

      data[props.url_slug]?.data.forEach((each: any) => {
        let fDate = formatDate(each?.attributes.date);
        data_array.push({
          id: each?.id,
          title: each?.attributes.title,
          description: each?.attributes.description.replaceAll(
            'src="/uploads',
            `src=\"${apiurl}/uploads`
          ),
          date: `${fDate.day} ${fDate.monthShort}, ${fDate.year}`,
          url: each?.attributes.url,
          attachment: each?.attributes.attachment.data ? each?.attributes.attachment.data?.attributes.url : "NA",
        });
      });
      setapiData({
        data: data_array,
        lastPage:
          data.news?.meta?.pagination.pageCount === apiData.currentPage
            ? true
            : false,
        currentPage: apiData.currentPage,
      });
      setIsLoadState(false);
    },
  });

  function circloader() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          width: "100vw",
        }}
      >
        <CircularProgress />
        <h4>Loading...</h4>
      </div>
    );
  }

  function leftClick() {
    setapiData({
      data: apiData.data,
      lastPage: apiData.lastPage,
      currentPage: apiData.currentPage - 1 < 1 ? 1 : apiData.currentPage - 1,
    });
    refetch();
  }

  function rightClick() {
    setapiData({
      data: apiData.data,
      lastPage: apiData.lastPage,
      currentPage: apiData.currentPage + 1,
    });
    refetch();
  }

  function navButtons() {
    let lbs = true;
    apiData.currentPage === 1 ? (lbs = true) : (lbs = false);

    return (
      <div
        style={{
          justifyContent: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
          display: "flex",
        }}
      >
        <div>
          {apiData.currentPage !== 1 ? (
            <>
              <Button
                variant="contained"
                color="primary"
                // disabled={lbs}
                style={{ marginRight: "10px" }}
                startIcon={<NavigateBeforeIcon />}
                onClick={leftClick}
              >
                Prev
              </Button>
            </>
          ) : (
            <></>
          )}
          {!apiData.lastPage ? (
            <>
              <Button
                variant="contained"
                color="primary"
                // disabled={apiData.lastPage}
                style={{ marginLeft: "10px" }}
                endIcon={<NavigateNextIcon />}
                onClick={rightClick}
              >
                Next
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }

  function newsbody() {
    return (
      <>
        {apiData.data.length !== 0 ? (
          <Container
            style={{
              width: matches ? "80%" : "100%",
              // marginLeft: "40px",
              // marginRight: "40px",
              marginBottom: "40px",
              minHeight: "30vh",
            }}
          >
            {apiData.data?.map(function (obj, index) {
              return (
                <div style={{ paddingTop: "40px" }} key={obj.id}>
                  <Card className="pdbga">
                    <CardContent>
                      <Container
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {matches ? (
                          <>
                            <h2 style={{ fontSize: "1.6rem", maxWidth: "70%" }}>
                              {obj.title}
                            </h2>
                            <p style={{ fontSize: "0.9rem" }}>
                              Published on: &nbsp;
                              {obj.date}
                            </p>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <h2 style={{ fontSize: "1.4rem" }}>{obj.title}</h2>
                            <p style={{ fontSize: "0.8rem" }}>
                              Published on: &nbsp;
                              {obj.date}
                            </p>
                          </div>
                        )}
                      </Container>
                      <div
                        className={"ql-container ql-snow"}
                        style={{
                          fontSize: "14px",
                          marginTop: "20px",
                          padding: "20px",
                          width: matches ? "90%" : "100%",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "justify",
                            textJustify: "inter-word",
                          }}
                          className={"ql-editor"}
                        // dangerouslySetInnerHTML={{ __html: obj.description }}
                        >
                          <ReactMarkdown >{obj.description}</ReactMarkdown>
                        </div>
                      </div>
                      <div style={{ marginTop: "50px" }}>
                        {obj.url !== "NA" && obj.url !== null ? (
                          <a href={obj.url}>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                marginRight: "10px",
                                marginBottom: matches1 ? "20px" : "0px",
                                fontSize: !matches ? "0.8rem" : "0.9rem",
                              }}
                            >
                              Learn More
                            </Button>
                          </a>
                        ) : (
                          <></>
                        )}
                        {obj.attachment !== "NA" && obj.attachment !== null ? (
                          <a href={apiurl + obj.attachment} download>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                fontSize: !matches ? "0.7rem" : "0.9rem",
                              }}
                            >
                              Download Attachment
                            </Button>
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
            {navButtons()}
          </Container>
        ) : (
          <Container
            style={{
              marginBottom: "40px",
              minHeight: "43vh",
            }}
          >
            <p
              style={{
                marginTop: "40px",
                marginLeft: "40px",
              }}
            >
              No Data Available ....
            </p>
          </Container>
        )}
      </>
    );
  }

  return (
    <>
      <div>
        <h1
          style={{
            fontSize: "2.5rem",
            paddingLeft: !matches ? "30px" : "80px",
            paddingTop: "40px",
            marginBottom: "30px",
            color: "#03205c",
          }}
        >
          {props.category}
        </h1>
        {!isLoadState ? newsbody() : circloader()}
      </div>
    </>
  );
}

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { BsFacebook } from 'react-icons/bs';
import { FaInstagram, FaYoutube } from 'react-icons/fa';

export default function Primaryfooter() {
  const matches = useMediaQuery("(min-width:900px)");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      fleft: {
        width: !matches ? "100%" : "50%",
      },

      fright: {
        width: "50%",
      },
      footerbg: {
        backgroundColor: "#D3D3D3",
      },
      contactus: {
        marginLeft: "40px",
        padding: "10px",
      },

      contactusmob: {
        paddingLeft: "40px",
        paddingRight: "40px",
      },

      cright: {
        fontFamily: "Karla",
        marginRight: isMobile ? "0px" : "20px",
      },
      fLarge: {
        fontWeight: "bolder",
        fontSize: "16px",
        color: "#000",
      },
      iconsright: {
        marginRight: "20px",
      }
    })
  );

  const classes = useStyles();
  var d = new Date();
  let currentYear = d.getFullYear();

  return (
    <div className={classes.footerbg}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={!matches ? "column" : "row"}
      >
        <div className={classes.fleft}>
          <Box justifyContent={"flex-start"}>
            <div className={matches ? classes.contactus : classes.contactusmob}>
              <h2
                style={{
                  marginBottom: "5px",
                  fontFamily: "Montserrat",
                  fontWeight: "bolder",
                  paddingBottom: "5px",
                }}
              >
                Contact Us
              </h2>
              <Divider />
              <p
                style={{
                  marginBottom: "5px",
                  fontFamily: "Karla",
                  lineHeight: "150%",
                  paddingBottom: "20px",
                }}
              >
                The poor and the marginalised people of the society, including the women and children.
                <br />
                <br />
                Contact No :{" "} 0495-2223022 ,2222390
                <br /> E-mail : {"   "}
                <a
                  href="mailto:codtmsy@gmail.com"
                  style={{ color: "#000" }}
                >
                  codtmsy@gmail.com
                </a>
                <br />
                Address :{"  "}Cod Thamarassery
                <br />
                Location :{"  "}Matha Towers,Thamarassery, P.B.No.33<br />Thamarassery P.O<br />Calicut-673 573, Kerala, India
                <br />
                <br />
                <a href="https://www.facebook.com/CODThamrassery/" target="blank" className={classes.iconsright}><BsFacebook color="black" fontSize={matches ? "1.5rem" : "1.2rem"} /></a>
                <a href="https://instagram.com/cod_thamarassery?igshid=YmMyMTA2M2Y=" className={classes.iconsright} target="blank" ><FaInstagram color="black" fontSize={matches ? "1.5rem" : "1.2rem"} /></a>
                <a href="" className={classes.iconsright} ><FaYoutube color="black" fontSize={matches ? "1.5rem" : "1.2rem"} /></a>
              </p>
            </div>
          </Box>
        </div>

        {matches ? (
          <div className={classes.fright}>
            <Box>
              <div
                style={{
                  padding: "10px",
                }}
              >
                <iframe
                  title="myFrame"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15728.112621662977!2d75.936474!3d11.4180065!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba668fb1d1be0db%3A0x3252a611224048d1!2sCentre%20for%20Overall%20Development!5e0!3m2!1sen!2sus!4v1618806949045!5m2!1sen!2sus"
                  width="85%"
                  height="250px"
                  style={{
                    border: "none",
                    padding: "10px",
                    paddingLeft: "20px",
                  }}
                  allowFullScreen={false}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Box>
          </div>
        ) : (
          <></>
        )}
      </Box>
      <div style={{ textAlign: "center", paddingBottom: "30px" }}>
        <small>&copy; Copyright {currentYear}, Cod Thamarassery</small>
      </div>
    </div>
  );
}

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import MobileStepper from "@material-ui/core/MobileStepper";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React from "react";
import { isMobile } from "react-device-detect";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useQuery, gql } from "@apollo/client";
import { useState } from "react";
import { apiurl } from "../varconfig";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const svgImg =
  'data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 50" enable-background="new" xml:space="preserve"><polygon points="0,0 0,20 100,40 100,0" style="fill:rgb(191,252,248)"/></svg>';

function SwipeableTextMobileStepper() {
  const [apiData, setApiData] = useState([
    {
      label: "Loading..",
      position: "Loading..",
      imgPath: "Loading..",
      imgdef: "Loading..",
    },
  ]);
  const matches = useMediaQuery("(min-width:840px)");

  const GET_TESTIMONIALS = gql`
  query {
    home {
      data{
        attributes {
          Testimonials(sort: "position_id") {
            name
            position_id
            position
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
  `;

  const { loading, error, data } = useQuery(GET_TESTIMONIALS, {
    onCompleted(data) {
      let data_array: any = [];
      data?.home.data?.attributes?.Testimonials?.forEach((each: any) => {
        data_array.push({
          label: each.name,
          position: each.position,
          imgPath: each.image?.data == null ? "/static/person.jpg" : (apiurl + each.image?.data?.attributes?.url),
          imgdef: each.description,
        });
      });
      setApiData(data_array);
    },
  });

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = apiData.length;
  const [userNotHover, setUserNotHover] = React.useState(true);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    if (userNotHover) {
      setActiveStep(step);
    }
  };

  return (
    <Container
      maxWidth="md"
      style={{
        paddingBottom: matches ? "60px" : "120px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: matches ? "80px" : "20px",
          marginBottom: "20px",
        }}
      >
        <h1 style={{ fontFamily: "Montserrat,sans-serif", fontWeight: "bold" }}>
          TESTIMONIALS
        </h1>
      </div>
      <div
        onMouseEnter={() => {
          setUserNotHover(false);
        }}
        onMouseLeave={() => {
          setUserNotHover(true);
        }}
      >
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {apiData.map((step, index) => (
            <Box
              key={step.label}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Card
                style={{
                  padding: "15px",
                  paddingBottom: "20px",
                  display: "flex",
                  backgroundSize: "cover",
                  backgroundImage: "url('" + svgImg + "')",
                  flexDirection: !matches ? "column" : "row",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img
                      style={{
                        height: "150px",
                        width: "140px",
                        borderRadius: "100%",
                        marginTop: " 1em",
                        padding: "20px",
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                  <div
                    style={{
                      backgroundColor: "none",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontFamily: "sans-serif",

                      }}
                    >
                      {apiData[activeStep].label}
                    </Typography>
                  </div>
                </div>

                <Box style={{ padding: "15px", paddingLeft: "40px" }}>
                  <Typography
                    variant="h6"
                    style={{
                      fontFamily: "Montserrat,sans-serif",
                      marginTop: "0vh",
                      textSizeAdjust: "none",
                      textAlign: "justify",
                      textJustify: "inter-word",
                      fontStyle: "italic",
                    }}
                  >
                    {apiData[activeStep].imgdef}
                    <br />
                    <br />
                    <b>{apiData[activeStep].position}</b>
                  </Typography>
                </Box>
              </Card>
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Container>
  );
}

export default SwipeableTextMobileStepper;

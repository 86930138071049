import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { apiurl } from "../../varconfig";
import { useQuery, gql } from "@apollo/client";
import { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface AppProps {
  url_slug: string;
  child_url: string;
}

export default function Primarynews(props: AppProps) {
  const [isLoadState, setIsLoadState] = useState(true);
  const [apiData, setapiData] = useState({
    data: [
      {
        id: 0,
        title: "Loading...",
      },
    ],
  });

  const matches = useMediaQuery("(min-width:840px)");

  const GET_PAGE = gql`
  query{
    news (sort:"date"){
       data{ 
         id
         attributes{
           title
         }
       }
     }
   }
  `;

  const { loading, error, data} = useQuery(GET_PAGE, {
    onCompleted(data) {
      let data_array: any = [];

      data.news?.data.forEach((each: any) => {
        data_array.push({
          id: each?.id,
          title: each?.attributes.title,
        });
        setapiData({
          data: data_array,
        });
        setIsLoadState(false);
      });

    },
  });

  function mapfunc(each: any, index: any) {
    return (
      <li key={index}>
        <a href={"/" + props.child_url}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "20px",
              color: index === 0 ? "orange" : "white",
            }}
          >
            {each.title}
          </Typography>
        </a>
      </li>
    );
  }

  return (
    <Card
      id="qlinksa"
      style={{
        padding: "0px",
        height: matches ? "250px" : "200px",
        marginBottom: "20px",
      }}
    >
      <CardContent style={{ fontFamily: "Karla,sans-serif" }}>
        <div>
          <Typography
            variant="h4"
            style={{
              fontFamily: "Montserrat,sans-serif",
              fontWeight: "bolder",
              marginBottom: "10px",
              paddingBottom: "0",
            }}
          >
            NEWS
          </Typography>
        </div>
        <div style={{ overflow: "hidden" }}>
          <div className="marquee">
            <ul style={{ marginBottom: "5px" }}>
              {apiData.data
                .reverse()
                .map((each, index) => mapfunc(each, index))}
            </ul>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

//@ts-nocheck
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { apiurl } from "../../varconfig";

const client = new ApolloClient({
  uri: apiurl + "/graphql",
  cache: new InMemoryCache(),
});

export const Apollo = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

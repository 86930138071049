import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import PrimaryCarousel from "../Carouselfold/CarouselComp";
import PrimaryDepro from "../HomeAbout";
import Primarymv from "../MissionandVision";
import Primarynews from "../Newsfold/Newsindex";
import SwipeableTextMobileStepper from "../Testimonials";

export default function Mobhome() {
  return (
    <div id="bg" style={{ width: "100%" }}>
      <div id="carsamob">
        <PrimaryCarousel url_slug="carousel" child_url="home" />
      </div>
      <div id="botcardsa">
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "-40px",
          }}
        >
          <div id="mobdepro">
            <PrimaryDepro />
          </div>
          <div id="newsindex">
            <Primarynews url_slug="news" child_url="news" />
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Box display="flex">
              <Primarymv child_url="home" />
            </Box>
          </div>
        </Container>
      </div>
      <SwipeableTextMobileStepper />
    </div>
  );
}

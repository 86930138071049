import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Component, lazy, Suspense } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Primaryfooter from "./components/Footer";
import Choosehome from "./components/Homefold/Homemanager";
import Choosenav from "./components/NavigBar/Nvgmanager";
import Newspagecomp from "./components/Newsfold/Newspage";
import PageNotFound from "./components/Notfound/Notfound" 

import "./styles/editedquill.snow.min.css";
import "./styles/editedquilltable.css";
import "./styles/style.css";


import { Apollo } from "./components/appolo/client";

// Lazy loading
const Staticpage = lazy(
  () => import("./components/Staticpagefold/Staticmanager")
);
const VolunteerPage = lazy(
  () => import("./components/VolunteerFold/volunteer")
);
const Projectlist = lazy(() => import("./components/Projectfold/ProjectList"));
const Projectpage = lazy(() => import("./components/Projectfold/Projectpecific"));
const ContactPage = lazy(() => import("./components/ContactFold/Contactpage"));
const ActivityPage = lazy(() => import("./components/Activityfold/Activitymanager"));
const AnnualReportPage = lazy(() => import("./components/AnnualReportFold/AnnualReport"));
const DonatePage = lazy(() => import("./components/Donatefold/DonatePage"));
const Gallery = lazy(() => import("./components/GalleryFold/gallery"));
const CredentialsPage = lazy(() => import("./components/CredentialsFold/CredentialsPage"));
const GoverningBodyPage = lazy(() => import("./components/GoverningBodyFold/GoverningbodyPage"));
const SupportPage = lazy(() => import("./components/SupportFold/SupportPage"));



interface AppProps { }
interface AppState {
  isLoaded: boolean;
}

// ------------ THEME COLOR ---------------------
// #062a51
// #26a69a

const theme = createMuiTheme({
  typography: {
    fontFamily: "Karla,sans-serif,Montserrat",
    h6: {
      fontSize: "15px",
    },
  },
  palette: {
    primary: {
      main: "#26a69a",
    },
    secondary: {
      main: "#fffff0",
    },
    text: {
      primary: "#062a51",
    },
  },
});

const circloaderfall = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        width: "100%",
      }}
    >
      <CircularProgress />
      <h4>Loading...</h4>
    </div>
  );
};
class App extends Component<AppProps, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoaded: true });
  }

  circloader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <CircularProgress />
        <h4>Loading...</h4>
      </div>
    );
  };

  renderChoose = () => {
    return (
      <Apollo>
        <Router>
          <Choosenav />
          <Suspense fallback={circloaderfall()}>
            <Switch>
              
              <Route exact path="/">
                <Choosehome />
              </Route>

              {/* About US */}
              <Route exact path="/aboutus/organizational_profile">
                <Staticpage title="organizational_profile" category="aboutUs" />
              </Route>
              <Route exact path="/aboutus/history">
                <Staticpage title="history" category="aboutUs" />
              </Route>
              <Route exact path="/aboutus/governing_body">
                <GoverningBodyPage />
              </Route>
              <Route exact path="/aboutus/miles_stones_and_achievements">
                <Staticpage title="miles_stones_and_achievements" category="aboutUs" />
              </Route>
              <Route exact path="/aboutus/credentials">
                <CredentialsPage/>
              </Route>
              <Route exact path="/aboutus/impact">
                <Staticpage title="impact" category="aboutUs" />
              </Route>

              {/* Projects */}
              <Route exact path="/projects/:projectid">
                <Projectpage />
              </Route>
              <Route exact path="/projects">
                <Projectlist />
              </Route>

              {/* Home */}
              <Route exact path="/activities">
                <ActivityPage />
              </Route>

              {/* getInvolved */}
              <Route exact path="/getInvolved/donate">
                <DonatePage />
              </Route>
              <Route exact path="/getInvolved/jobs">
                <Staticpage title="jobs" category="getInvolved" />
              </Route>
              <Route exact path="/getInvolved/volunteers">
                <VolunteerPage />
              </Route>
              <Route exact path="/getInvolved/internship">
                <Staticpage title="internship" category="getInvolved" />
              </Route>
              <Route exact path="/getInvolved/individual_support">
                <SupportPage category="individualSupport" title="individual_support"/>
              </Route>
              <Route exact path="/getInvolved/coporate_partnership">
                <SupportPage category="coporatePartnership" title="coporate_partnership"/>
              </Route>


              {/* Media */}
              <Route exact path="/gallery">
                <Gallery />
              </Route>
              <Route exact path="/news">
                <Newspagecomp url_slug="news" category="NEWS"/>
              </Route>
              <Route exact path="/annual_report">
                <AnnualReportPage />
              </Route>
              <Route exact path="/story_of_change">
              <Newspagecomp url_slug="storyOfChanges" category="STORIES OF CHANGES"/>
              </Route>
              <Route exact path="/blogs">
              <Newspagecomp url_slug="blogs" category="BLOGS"/>
              </Route>

              {/* Contact */}
              <Route exact path="/contact_us">
                <ContactPage />
              </Route>

              <Route path="*" component={PageNotFound} />
              
            </Switch>
          </Suspense>
          <Primaryfooter />
        </Router>
      </Apollo>
    );
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        {this.state.isLoaded ? this.renderChoose() : this.circloader()}
      </ThemeProvider>
    );
  }
}

render(<App />, document.getElementById("root"));

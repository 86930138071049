import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: "60px",
    },
    groupa: {
      paddingLeft: "24px",
      width: "100%",
    },
    headinga: {
      marginBottom: "20px",
      fontFamily: "Montserrat,sans-serif",
    },
  })
);

export default function MobileNavBar() {
  const classes = useStyles();
  const [dstate, setdstate] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setdstate(!dstate);
    };

  return (
    <div>
      <AppBar position="static" style={{ paddingTop: "5px" }}>
        <Toolbar>
          <div>
            <a href="/">
              <img
                className={classes.logo}
                src="/static/logo.png"
                alt="COD logo"
              />
            </a>
          </div>
          <div className={classes.groupa}>
            <a href="/">
              <h4 className={classes.headinga}>
                COD THAMARASSERY
              </h4>
            </a>
          </div>
          <div>
            <IconButton
              onClick={toggleDrawer(true)}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={dstate}
              onClose={toggleDrawer(true)}
              style={{ fontFamily: "Karla" }}
            >
              <a href="/">
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel6a-content"
                    id="panel6a-header"
                  >
                    <h4 style={{ margin: "0px" }}>HOME</h4>
                  </AccordionSummary>
                </Accordion>
              </a>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 style={{ margin: "0px" }}>ABOUT US</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem button component="a" {...{ href: "/aboutus/organizational_profile" }}>
                      <ListItemText primary="Organizational Profile" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/aboutus/history" }}>
                      <ListItemText primary="History" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/aboutus/governing_body" }}>
                      <ListItemText primary="Governing Body" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/aboutus/miles_stones_and_achievements" }}>
                      <ListItemText primary="Mile Stones and Achievements" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/aboutus/credentials" }}>
                      <ListItemText primary="Credentials" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/aboutus/impact" }}>
                      <ListItemText primary="Impact" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <a href="/projects">
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                  >
                    <h4 style={{ margin: "0px" }}>PROJECTS</h4>
                  </AccordionSummary>
                </Accordion>
              </a>
              <a href="/activities">
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                  >
                    <h4 style={{ margin: "0px" }}>ACTIVITIES</h4>
                  </AccordionSummary>
                </Accordion>
              </a>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <h4 style={{ margin: "0px" }}>GET INVOLVED</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem
                      button
                      component="a"
                      {...{ href: "/getInvolved/donate" }}
                    >
                      <ListItemText primary="Donate Now" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/getInvolved/jobs" }}>
                      <ListItemText primary="Jobs" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/getInvolved/volunteers" }}>
                      <ListItemText primary="Volunteers" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/getInvolved/internship" }}>
                      <ListItemText primary="Internship" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/getInvolved/individual_support" }}>
                      <ListItemText primary="Individual Support" />
                    </ListItem>
                    <ListItem button component="a" {...{ href: "/getInvolved/coporate_partnership" }}>
                      <ListItemText primary="Corporate Partnership" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <h4 style={{ margin: "0px" }}>MEDIA</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <ListItem button component="a" {...{ href: "/gallery" }}>
                      <ListItemText primary="Gallery" />
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      {...{ href: "/news" }}
                    >
                      <ListItemText primary="News" />
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      {...{ href: "/annual_report" }}
                    >
                      <ListItemText primary="Annual Reports" />
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      {...{ href: "/story_of_change" }}
                    >
                      <ListItemText primary="Stories Of Change" />
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      {...{ href: "/blogs" }}
                    >
                      <ListItemText primary="Blogs" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <a href="/contact_us">
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                  >
                    <h4 style={{ margin: "0px" }}>CONTACT</h4>
                  </AccordionSummary>
                </Accordion>
              </a>
            </Drawer>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import PrimaryCarousel from "../Carouselfold/CarouselComp";
import PrimaryDepro from "../HomeAbout";
import Primarymv from "../MissionandVision";
import Primarynews from "../Newsfold/Newsindex";
import Primaryqlinks from "../Quicklinks";
import SwipeableTextMobileStepper from "../Testimonials";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Deskhome() {
  const matches = useMediaQuery("(min-width:840px)");
  return (
    <div id="bg">
      <div id="carsa">
        <PrimaryCarousel url_slug="carousel" child_url="home" />
      </div>
      <div id="botcardsadesk">
        <Container style={{ display: "flex" }}>
          <div id="lmain">
            <PrimaryDepro />
          </div>
          <div id="rmain">
            <div>
              <Primaryqlinks />
            </div>
            {<div id="newsindex">
              <Primarynews url_slug="newsblog" child_url="news" />
            </div>}
          </div>
        </Container>

        <Container style={{ display: "flex", marginTop: "60px" }}>
          <div style={{ width: "100vw", padding: "15px" }}>
            <Box display="flex">
              <Primarymv child_url="home" />
            </Box>
          </div>
        </Container>

        <div>
          <SwipeableTextMobileStepper />
        </div>
      </div>
    </div>
  );
}

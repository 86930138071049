import useMediaQuery from "@material-ui/core/useMediaQuery";
import MobileNavBar from "./Mobnvgbar";
import PrimaryNavBar from "./Nvgbar";

export default function Choosenav() {
  const matches = useMediaQuery("(min-width:1150px)");
  if (matches) {
    return <PrimaryNavBar />;
  } else {
    return <MobileNavBar />;
  }
}

export function formatDate(date) {
  const d = new Date(date);
  // Checking if date invalid
  if (!(d instanceof Date && !isNaN(d)))
    return {
      hours: "", // 12 - 11
      amPm: "", // 'AM', 'PM'
      hours24: "", // 0 - 23
      hours24String: "", // 00 - 23
      minutes: "", // 0 - 59
      minutesString: "", // '00' - '59'

      day: "", // 1 - 31
      month: "", // 1 - 12
      year: "",
      monthShort: "", // 'Jan' - 'Dec'
      monthLong: "", // 'January' - 'December'

      dateStringShort: "", // 1 Jan 2021
      dateStringLong: "", // 1 January 2021
      dateString: "", // 1-1-2021
      dateStringResponsive: "", // 1 Jan, 1 Jan 2022

      timeString: "", // 1:04 AM
      timeString24: "", // 00:12
    };

  // If date valid
  const monthsShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthsLong = [
    "January",
    "Febuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let hours = d.getHours() % 12;
  hours += hours === 0 ? 1 : 0;
  const amPm = hours >= 12 ? "AM" : "PM";
  const hours24 = d.getHours();
  const minutes = d.getMinutes();
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const monthShort = monthsShort[month - 1];
  const monthLong = monthsLong[month - 1];

  const currentYear = new Date().getFullYear();

  const dateStringShort = `${day} ${monthShort} ${year}`;
  const dateStringLong = `${day} ${monthLong} ${year}`;
  const dateString = `${day}-${month}-${year}`;
  const dateStringResponsive =
    year !== currentYear
      ? `${day} ${monthShort} ${year}`
      : `${day} ${monthShort}`;

  const hours24String = hours24 < 10 ? `0${hours24}` : hours24;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  const timeString = `${hours}:${minutesString} ${amPm}`;
  const timeString24 = `${hours24String}:${minutesString}`;

  return {
    hours,
    amPm,
    hours24,
    hours24String,
    minutes,
    minutesString,

    day,
    month,
    year,
    monthShort,
    monthLong,

    dateStringShort,
    dateStringLong,
    dateString,
    dateStringResponsive,

    timeString,
    timeString24,
  };
}

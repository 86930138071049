import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useQuery, gql } from "@apollo/client";
import { useState } from "react";
import { donateurl } from "../varconfig";
import { Button, CircularProgress } from "@material-ui/core";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ReactMarkdown from "react-markdown";

export default function PrimaryDepro() {
  const [apiData, setApiData] = useState("Loading...");

  const GET_HOME = gql`
    query {
      home {
        data {
          attributes {
            description
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_HOME, {
    onCompleted(data) {
      setApiData(data.home.data.attributes.description);
    },
  });
  function circloader() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
          width: "100%",
        }}
      >
        <CircularProgress />
        <h4>Loading...</h4>
      </div>
    );
  }
  return (
    !loading ?
      <Card className="pdbga">
        <CardContent>
          <Typography
            variant="h4"
            style={{
              fontFamily: "Karla,sans-serif",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            WELCOME TO COD THAMARASSERY
          </Typography>
          <Typography
            variant="h6"
            style={{
              fontFamily: "Montserrat,sans-serif",
              fontSize: "1rem",
              marginTop: "5vh",
              marginLeft: "1vh",
              justifyContent: "center",
              textAlign: "center"
            }}
          >
            <div
              className={"ql-editor"}
              style={{
                textAlign: "justify",
                textJustify: "inter-word"
              }}
              // dangerouslySetInnerHTML={{
              //   __html: apiData,
              // }}
            >
              <ReactMarkdown >{apiData}</ReactMarkdown>
            </div>
            <br />
            <a href={"/getInvolved/donate"}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  fontSize: "1rem",
                }}
                endIcon={<VolunteerActivismIcon />}
              >
                Click Here To Donate
              </Button>
            </a>
          </Typography>

        </CardContent>
      </Card> : circloader()
  );
}
